<template>
  <div class="login-container">
    <div class="login-form">
      <div style="margin: 20px; font-size: 20px; font-weight: bold">
        用户登录
      </div>
      <n-form
        ref="formRef"
        :model="formData"
        label-placement="left"
        style="width: 70%"
      >
        <n-form-item label="账号" path="user.name" :label-style="labelStyle">
          <n-input v-model:value="formData.user" placeholder="请输入账号" />
        </n-form-item>
        <n-form-item label="密码" path="user.age" :label-style="labelStyle">
          <n-input
            v-model:value="formData.password"
            type="password"
            placeholder="请输入密码"
          />
        </n-form-item>
      </n-form>
      <n-button type="info" style="width: 70%; margin: 20px" @click="onLogin"
        >登录</n-button
      >
    </div>
    <img class="login-bg" src="@/assets/login_bg.jpg" alt="" />
  </div>
</template>

<script setup>
import { ref } from "vue";
import router from "../router";
import { NForm, NFormItem, NInput, NButton } from "naive-ui";
import { login } from "@/api/coin";

let formData = ref({
  user: "",
  password: "",
});

let labelStyle = ref({
  color: "#333333",
});

function onLogin() {
  if(formData.value.user ==='' || formData.value.password ===''){
      window.$message.warning("用户名和密码不能为空");
    return;
  }
  login({
    userName: formData.value.user,
    password: formData.value.password,
  }).then((res) => {
    console.log("error", res);
    if (res.code == 200) {
      sessionStorage.setItem("userName", formData.value.user);
      router.push("/home");
    } else {
      console.log("error");
      window.$message.warning("用户不存在或者密码错误");
    }
  }).catch(err=>{
      console.log("error",err);
      window.$message.warning("用户不存在或者密码错误");
  });
}
</script>

<style>
.login-container {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-bg {
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.login-form {
  width: 400px;
  background-color: #fff;
  border-radius: 8px;
  color: #333;
  z-index: 10;
  /* padding: 20px; */
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.n-input {
  width: 100%;
}
</style>

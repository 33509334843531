<template>
  <n-spin :show="showloading">
    <n-form
      ref="formRef"
      :model="formData"
      label-placement="left"
      :label-width="120"
      :rules="rules"
    >
      <n-grid :cols="2" :x-gap="30">
        <n-form-item-gi :span="2" label="编号" path="sn">
          <n-input v-model:value="formData.sn" placeholder="请输入编号" />
        </n-form-item-gi>
        <n-form-item-gi label="名称" path="name">
          <n-input v-model:value="formData.name" placeholder="请输入名称" />
        </n-form-item-gi>
        <n-form-item-gi label="Product name" path="nameEn">
          <n-input v-model:value="formData.nameEn" placeholder="Product name" />
        </n-form-item-gi>
        <n-form-item-gi label="面值" path="facevalue">
          <n-input
            v-model:value="formData.facevalue"
            placeholder="请输入面值"
          />
        </n-form-item-gi>
        <n-form-item-gi label="Denomination" path="facevalueEn">
          <n-input
            v-model:value="formData.facevalueEn"
            placeholder="Denomination"
          />
        </n-form-item-gi>
        <n-form-item-gi label="评级" path="certInfo">
          <n-input v-model:value="formData.certInfo" placeholder="请输入评级" />
        </n-form-item-gi>
        <n-form-item-gi label="Certification information" path="certInfoEn">
          <n-input
            v-model:value="formData.certInfoEn"
            placeholder="Certification information"
          />
        </n-form-item-gi>
        <n-form-item-gi label="材质" path="material">
          <n-input v-model:value="formData.material" placeholder="请输入材质" />
        </n-form-item-gi>
        <n-form-item-gi label="Material" path="materialEn">
          <n-input v-model:value="formData.materialEn" placeholder="Material" />
        </n-form-item-gi>
        <n-form-item-gi label="详细信息" path="detail">
          <n-input
            v-model:value="formData.detail"
            placeholder="请输入详细信息"
            type="textarea"
            maxlength="200"
            :autosize="{
              minRows: 1,
              maxRows: 3,
            }"
          />
        </n-form-item-gi>
        <n-form-item-gi label="发行日期" path="releasedate">
          <n-date-picker
            value-format="yyyy-MM-dd"
            placeholder="请选择发行日期"
            type="date"
            style="width: 100%"
            v-model:formatted-value="selectDate"
          />
        </n-form-item-gi>
        <n-form-item-gi label="图片（正面）" path="frontImg">
          <n-upload
            :max="1"
            list-type="image-card"
            :file-list="frontImg"
            @update:file-list="handleFrontImgChange"
            @before-upload="beforeUpload"
          >
          </n-upload>
        </n-form-item-gi>
        <n-form-item-gi label="图片（反面）" path="backImg">
          <n-upload
            :max="1"
            list-type="image-card"
            :file-list="backImg"
            @update:file-list="handleBackImgChange"
            @before-upload="beforeUpload"
          >
          </n-upload>
        </n-form-item-gi>
      </n-grid>
    </n-form>
    <div class="button-container">
      <n-button type="" style="margin: 10px" @click="onCancelClick"
        >取消</n-button
      >
      <n-button type="info" style="margin: 10px" @click="onSaveClick"
        >保存</n-button
      >
    </div>
    <img @error="imgOnFrontError" :src="frontimgPath" v-show="false" />
    <img @error="imgOnBackError" :src="backimgPath" v-show="false" />
  </n-spin>
</template>

<script setup>
import { ref, defineEmits, defineProps, toRefs } from "vue";
import { addInfo, baseUrl } from "@/api/coin";
// import axios from "axios"
// import ActiveXObject
import {
  NForm,
  NGrid,
  NFormItemGi,
  NInput,
  NButton,
  NDatePicker,
  NUpload,
  NSpin,
} from "naive-ui";

const props = defineProps({
  selectProduct: {
    type: Object,
    default: () => {
      return null;
    },
  },
});
const { selectProduct } = toRefs(props);
let formData = ref({
  sn: "",
  name: "",
  facevalue: "",
  material: "",
  detail: "",
  releasedate: null,
});
let frontImg = ref([]);
let backImg = ref([]);
let selectDate = ref(null);
let showloading = ref(false);
const formRef = ref(null);
const emit = defineEmits(["save", "cancel"]);

let frontimgPath = baseUrl() + "/uploadimg/" + selectProduct.value?.sn + ".jpg";
let backimgPath =
  baseUrl() + "/uploadimg/" + selectProduct.value?.sn + "_back.jpg";

if (selectProduct.value?.id) {
  // formData = selectProduct.value;
  formData.value = {
    id: selectProduct.value.id,
    sn: selectProduct.value.sn,
    name: selectProduct.value.name,
    facevalue: selectProduct.value.facevalue,
    material: selectProduct.value.material,
    detail: selectProduct.value.detail,
    releasedate: selectProduct.value.releasedate,
    nameEn: selectProduct.value.nameEn,
    facevalueEn: selectProduct.value.facevalueEn,
    materialEn: selectProduct.value.materialEn,
    certInfo: selectProduct.value.certInfo,
    certInfoEn: selectProduct.value.certInfoEn,
    frontimg: selectProduct.value.frontimg,
    backimg: selectProduct.value.backimg,
  };
  if (
    selectProduct.value.releasedate &&
    selectProduct.value.releasedate.length >= 10
  )
    selectDate.value = selectProduct.value.releasedate.substring(0, 10);
  frontImg.value = [
    {
      id: 1,
      url: frontimgPath,
      status: "finished",
      name: "正面",
    },
  ];
  backImg.value = [
    {
      id: 1,
      url: backimgPath,
      status: "finished",
      name: "背面",
    },
  ];
}
function imgOnFrontError(error) {
  console.log("--err", error);
  frontImg.value = [];
}
function imgOnBackError(error) {
  console.log("--err", error);
  backImg.value = [];
}
function onCancelClick() {
  emit("cancel");
}
function onSaveClick() {
  if (selectDate.value) formData.value.releasedate = getWeek(selectDate.value);
  formRef.value?.validate(async (errors) => {
    if (!errors) {
      showloading.value = true;
      console.log(formData);
      addInfo(formData.value)
        .then((res) => {
          if (res.code == 200) {
            window.$message.success("保存成功");
            emit("save");
          } else {
            window.$message.warning("请求失败，请稍后再试");
          }
          showloading.value = false;
        })
        .catch((error) => {
          console.log("error", error);
          showloading.value = false;
          window.$message.warning(error.message);
        });
    }
  });
}

function getWeek(params) {
  let week = new Date(params).getDay();
  let weeks = ["日", "一", "二", "三", "四", "五", "六"];
  return params + " 星期" + weeks[week];
}

function handleFrontImgChange(options) {
  if (options.length) {
    formData.value.frontImg = options[0].file;
    frontImg.value = options;
  } else {
    formData.value.frontImg = null;
    frontImg.value = [];
  }
}

function handleBackImgChange(options) {
  if (options.length) {
    formData.value.backImg = options[0].file;
    backImg.value = options;
  } else {
    formData.value.backImg = null;
    backImg.value = [];
  }
}

function beforeUpload(data) {
  if (data.file.file?.type === "image/jpeg") {
    return true;
  }
  window.$message.warning("请上传jpg格式图片");
  return false;
}

let rules = ref({
  sn: [
    {
      required: true,
      message: "请输入编号",
    },
  ],
  name: [
    {
      required: true,
      message: "请输入名称",
    },
  ],
  nameEn: [
    {
      required: true,
      message: "请输入名称",
    },
  ],
  material: [
    {
      required: true,
      message: "请输入材质",
    },
  ],
  materialEn: [
    {
      required: true,
      message: "请输入材质",
    },
  ],
  facevalue: [
    {
      required: true,
      message: "请输入面值",
    },
  ],
  facevalueEn: [
    {
      required: true,
      message: "请输入面值",
    },
  ],
  certInfo: [
    {
      required: true,
      message: "请输入评级",
    },
  ],
  certInfoEn: [
    {
      required: true,
      message: "请输入评级",
    },
  ],
  // detail: [
  //   {
  //     required: true,
  //     message: "请请输入详细信息",
  //   },
  // ],
  releasedate: [
    {
      required: true,
      message: "请选择发行日期",
    },
  ],
  // backImg: [
  //   {
  //     required: true,
  //     message: "请选择图片",
  //   },
  // ],
  // frontImg: [
  //   {
  //     required: true,
  //     message: "请选择图片",
  //   },
  // ],
});
</script>

<style>
.button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>

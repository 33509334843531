<template>
  <n-spin :show="showloading">
    <div style="margin-bottom: 10px; color: #999999">
      【excel说明】文件数据格式为：第一行标题，列分别为编号、名称、面值、材质、详情、评级、发行日期、名称（英文）、面值（英文）、材质（英文）、评级（英文）。
    </div>
    <div style="margin-bottom: 30px; color: #999999">
      【图片说明】图片压缩包为zip格式，产品正面图片以编号命名，背面以编号_back格式命名。
    </div>
    <n-form
      ref="formRef"
      :model="formData"
      label-placement="top"
      :label-width="80"
      :rules="rules"
    >
      <n-grid :cols="2" :x-gap="30">
        <n-form-item-gi label="数据文件（excel）" path="excel">
          <n-upload
            :max="1"
            v-model:file-list="formData.excel"
            @before-upload="beforeExcelUpload"
          >
            <n-button>选择文件</n-button>
          </n-upload>
        </n-form-item-gi>
        <n-form-item-gi label="图片文件（.zip）" path="picZip">
          <n-upload
            :max="1"
            v-model:file-list="formData.picZip"
            @before-upload="beforePicZipUpload"
          >
            <n-button>选择文件</n-button>
          </n-upload>
        </n-form-item-gi>
      </n-grid>
    </n-form>
    <div class="button-container">
      <n-button type="" style="margin: 10px" @click="onCancelClick"
        >取消</n-button
      >
      <n-button type="info" style="margin: 10px" @click="onSaveClick"
        >保存</n-button
      >
    </div>
  </n-spin>
</template>

<script setup>
import { ref, reactive, defineEmits } from "vue";
import { batchUpload } from "@/api/coin";
import { NForm, NGrid, NFormItemGi, NButton, NUpload, NSpin } from "naive-ui";

let formData = reactive({
  excel: [],
  picZip: [],
});
let showloading = ref(false);
const formRef = ref(null);
const emit = defineEmits(["save", "cancel"]);
function onCancelClick() {
  emit("cancel");
}
function onSaveClick() {
  console.log(formData);
  if (formData.excel.length == 0 && formData.picZip.length == 0) {
    window.$message.warning("请选择excel或者zip图片压缩文件");
    return;
  }
  formRef.value?.validate(async (errors) => {
    if (!errors) {
      showloading.value = true;
      batchUpload(formData)
        .then((res) => {
          if (res.code == 200) {
            window.$message.success("保存成功");
            emit("save");
          } else {
            window.$message.warning("保存失败");
          }
          showloading.value = false;
        })
        .catch((error) => {
          console.log(error);
          showloading.value = false;
          window.$message.warning("保存失败");
        });
    }
  });
}

function beforeExcelUpload(data) {
  console.log(data.file.file);
  if (
    data.file.file?.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
    data.file.file?.type === "application/vnd.ms-excel"
  ) {
    return true;
  }
  window.$message.warning("请上传xlsx格式文件");
  return false;
}
function beforePicZipUpload(data) {
  console.log(data.file.file);
  if (
    (data.file.file?.type == "application/zip" ||
      data.file.file?.type == "application/x-zip-compressed") &&
    data.file.file?.size / 1000000 < 200
  ) {
    return true;
  }
  window.$message.warning("请上传200MB以内zip格式的压缩文件");
  return false;
}

let rules = ref({
  // excel: [
  //   {
  //     required: true,
  //     message: "请选择图片",
  //   },
  // ],
  // picZip: [
  //   {
  //     required: true,
  //     message: "请选择图片",
  //   },
  // ],
});
</script>

<style>
.button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>

<template>
  <n-spin :show="showloading">
    <div class="home-container">
      <div class="nav-bar">
        <!-- <div style="width: calc(100% - 100px)">
        <div class="nav-text" style="font-size: 20px">西安印钞有限公司</div>
        <div class="nav-text" style="font-size: 12px">
          XI'AN BANKNOTE PRINTING CO., LTD.
        </div>
      </div> -->

        <img
          class="company-logo"
          v-if="userName === 'cgcs'"
          src="@/assets/company_logo_cgcs.png"
          alt=""
        />
        <img
          class="company-logo"
          v-else
          src="@/assets/company_logo.png"
          alt=""
        />
        <div
          style="
            position: absolute;
            right: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
          "
        >
          <n-button
            v-if="userName === 'cgcs'"
            style="color: #fff; margin-right: 20px"
            size="small"
            @click="onAddNotice"
          >
            发布通知
          </n-button>
          <n-button
            style="color: #fff; margin-right: 20px"
            size="small"
            @click="onSearchSn"
          >
            编号查询
          </n-button>
          <n-button
            quaternary
            style="color: #fff"
            size="small"
            @click="onUpdatePassword"
            >修改密码
          </n-button>
          <div class="login-out" @click="onLoginOut">退出登录</div>
        </div>
      </div>

      <div class="content-container">
        <div style="display: flex; flex-direction: row">
          <div>
            <n-form inline label-placement="left" :model="searchInfo">
              <n-form-item label="编号">
                <n-input
                  v-model:value="searchInfo.sn"
                  placeholder="输入编号"
                  :maxlength="20"
                />
              </n-form-item>
              <n-form-item label="名称">
                <n-input
                  v-model:value="searchInfo.name"
                  :maxlength="15"
                  placeholder="输入名称"
                />
              </n-form-item>
              <n-form-item label="面值">
                <n-input
                  :maxlength="10"
                  v-model:value="searchInfo.facevalue"
                  placeholder="输入面值"
                  style="width: 80px"
                />
              </n-form-item>
              <n-form-item>
                <n-button style="margin-right: 10px" @click="onClearClick">
                  清空
                </n-button>
                <n-button type="info" @click="onSearchClick"> 查询 </n-button>
              </n-form-item>
            </n-form>
          </div>
          <div class="buttons-div">
            <n-button type="info" @click="onAdd" style="margin-right: 10px"
              >+ 添加</n-button
            >
            <n-button
              type="info"
              @click="onBatchUpload"
              style="margin-right: 10px"
              >导入</n-button
            >
            <n-button
              type="info"
              @click="onPrintCode"
              style="margin-right: 10px"
              >二维码打印</n-button
            >
            <n-dropdown
              trigger="hover"
              :options="dropdownOptions"
              @select="handleSelect"
            >
              <n-button type="info">下载</n-button>
            </n-dropdown>
          </div>
        </div>
        <div>
          <n-data-table
            :bordered="false"
            :single-line="false"
            :columns="tableColums"
            :data="listData.list"
            style="min-height: 480px; background-color: #fff"
          />
          <n-pagination
            style="margin-top: 10px"
            v-model:page="listData.pageNum"
            :item-count="listData.total"
            v-model:page-size="searchInfo.limit"
            :page-sizes="[10, 15, 20]"
            @updatePage="updatePage"
            @update:page-size="updatePageSize"
          >
            <!-- show-size-picker -->
            <template #prefix="{ itemCount }">
              共 {{ listData.total ? itemCount : 0 }} 条
            </template>
          </n-pagination>
        </div>
      </div>
    </div>
    <n-modal v-model:show="showEditModal">
      <n-card
        style="width: 60%; min-width: 500px; max-width: 1200"
        title="添加"
      >
        <coin-edit
          :selectProduct="selectProduct"
          @cancel="showEditModal = false"
          @save="save"
        ></coin-edit>
      </n-card>
    </n-modal>

    <n-modal v-model:show="showEditNoticeModal">
      <n-card
        style="width: 60%; min-width: 500px; max-width: 1200"
        title="发布通知"
      >
        <notice-edit
          :selectProduct="selectProduct"
          @cancel="showEditNoticeModal = false"
          @save="showEditNoticeModal = false"
        ></notice-edit>
      </n-card>
    </n-modal>
    <n-modal v-model:show="showBatUploadModal">
      <n-card style="min-width: 700px; max-width: 700px" title="批量导入">
        <batch-upload
          @cancel="showBatUploadModal = false"
          @save="save"
        ></batch-upload>
      </n-card>
    </n-modal>

    <n-modal v-model:show="showQRCodeModal">
      <n-card
        :style="{
          'max-width': '900px',
          width: mmToPx(46 * 3) + 'px',
          'min-width': mmToPx(46 * 3 + 30) + 'px',
        }"
        title="二维码打印"
      >
        <qrcode-list @cancel="showQRCodeModal = false"></qrcode-list>
      </n-card>
    </n-modal>

    <n-modal v-model:show="isShowQrcode">
      <n-card style="min-width: 300px; max-width: 300px; height: 300px">
        <qrcode-detail :selectProduct="selectProduct"></qrcode-detail>
      </n-card>
    </n-modal>
    <n-modal v-model:show="showModifyPassword">
      <n-card
        style="width: 500px; height: 320px"
        closable
        title="修改密码"
        @close="showModifyPassword = false"
      >
        <update-password
          @cancel="showModifyPassword = false"
          @save="showModifyPassword = false"
        ></update-password>
      </n-card>
    </n-modal>
  </n-spin>
</template>

<script setup>
import { ref, h, onMounted } from "vue";
import {
  NButton,
  NModal,
  NCard,
  NDataTable,
  NPagination,
  NForm,
  NFormItem,
  NInput,
  NDropdown,
  NSpin,
} from "naive-ui";
import router from "../router";
import coinEdit from "./component/coinEdit.vue";
import noticeEdit from "./component/noticeEdit.vue";
import QrcodeList from "./qrCodeList.vue";
import BatchUpload from "./component/BatchUpload.vue";
import QrcodeDetail from "./component/QrcodeDetail.vue";
import updatePassword from "./component/updatePassword.vue";
import { mmToPx } from "@/util/tools.js";
import * as api from "@/api/coin.js";

let showloading = ref(false);
let showEditModal = ref(false);
let showEditNoticeModal = ref(false);
let showQRCodeModal = ref(false);
let tableColums = createColumns();
let isShowQrcode = ref(false);
let showBatUploadModal = ref(false);
let selectProduct = ref(null);
let showModifyPassword = ref(false);
let userName = sessionStorage.getItem("userName");
let dropdownOptions = ref([
  {
    label: "产品数据下载",
    key: "1",
  },
  {
    label: "二维码下载",
    key: "2",
  },
]);
let searchInfo = ref({
  page: 1,
  limit: 10,
  sn: "",
  name: "",
  facevalue: "",
});
let listData = ref({
  list: [],
  total: 0,
});

onMounted(() => {
  queryListData();
});
function onSearchClick() {
  updatePage(1);
}
function onUpdatePassword() {
  showModifyPassword.value = true;
}
function onClearClick() {
  searchInfo.value.sn = "";
  searchInfo.value.name = "";
  searchInfo.value.facevalue = "";
  updatePage(1);
}
function onLoginOut() {
  router.push("/");
}
function onAdd() {
  showEditModal.value = true;
  selectProduct.value = null;
}
/* 发布通知 */
function onAddNotice() {
  selectProduct.value = null;
  showEditNoticeModal.value = true;
}
function onPrintCode() {
  showQRCodeModal.value = true;
}
function onBatchUpload() {
  showBatUploadModal.value = true;
}
function save() {
  showEditModal.value = false;
  showBatUploadModal.value = false;
  updatePage(1);
}
function updatePage(page) {
  searchInfo.value.page = page;
  queryListData();
}
function updatePageSize(val) {
  searchInfo.value.page = 1;
  searchInfo.value.limit = val;
  queryListData();
}

function trimSearchkeywords() {
  let result = { ...searchInfo.value };
  result.sn = result.sn.trim();
  result.name = result.name.trim();
  result.facevalue = result.facevalue.trim();

  if (result.sn === "") result.sn = "-"; /*  */
  result.detail = "";
  return result;
}
async function queryListData() {
  let { data } = await api.queryInfoList(trimSearchkeywords());
  listData.value = data;
  listData.value.list = data?.list.filter(
    (item) => item.sn !== "XC00000000000"
  );
}
function handleSelect(val) {
  console.log(val);
  if (val == 1) {
    onExportExcel();
  } else {
    onExportQRCode();
  }
}
function onExportExcel() {
  let userName = sessionStorage.getItem("userName");
  let user = userName === "admin" ? "1" : "2";
  showloading.value = true;
  api
    .exportExcel({
      ...trimSearchkeywords(),
      user: user,
    })
    .then((res) => {
      showloading.value = false;
      console.log("--", res);
      if (res.status == 200) {
        api.handleBlobFile(res);
      } else {
        window.$message.warning("下载失败");
      }
    })
    .catch(() => {
      showloading.value = false;
      window.$message.warning("下载失败");
    });
}
function onExportQRCode() {
  let userName = sessionStorage.getItem("userName");
  let user = userName === "admin" ? "1" : "2";
  showloading.value = true;
  api
    .exportQRCode({
      ...trimSearchkeywords(),
      user: user,
    })
    .then((res) => {
      showloading.value = false;
      console.log("--", res);
      if (res.status == 200) {
        api.handleBlobFile(res);
      } else {
        window.$message.warning("下载失败");
      }
    })
    .catch(() => {
      showloading.value = false;
      window.$message.warning("下载失败");
    });
}
function deleteRow(row) {
  window.$dialog.warning({
    title: "警告",
    content: "确定删除该条记录?",
    positiveText: "确定",
    negativeText: "取消",
    onPositiveClick: async () => {
      let { data } = await api.deleteInfo({ id: row.id });
      if (data > 0) {
        window.$message.success("删除成功");
        updatePage(1);
      } else {
        window.$message.warning("删除失败");
      }
    },
  });
}
function onEditRow(row) {
  console.log(row);
  showEditModal.value = true;
  selectProduct.value = row;
}
function toDetailPage(row) {
  let userName = sessionStorage.getItem("userName");
  let user = userName === "admin" ? "1" : "2";
  const { href } = router.resolve({
    path: "/detail",
    query: {
      code: row.id,
      u: user,
    },
  });
  window.open(href, "_blank");
}
function onSearchSn() {
  let path = "/search";
  if (userName != "admin") {
    path = "/cgcs";
  }
  const { href } = router.resolve({
    path: path,
  });
  window.open(href, "_blank");
}
function showQRCode(row) {
  isShowQrcode.value = true;
  selectProduct.value = row;
}
function createColumns() {
  return [
    {
      title: "编号",
      key: "sn",
    },
    {
      title: "名称",
      key: "name",
    },
    {
      title: "面值",
      key: "facevalue",
    },
    {
      title: "材质",
      key: "material",
    },
    {
      title: "发行日期",
      key: "releasedate",
    },
    {
      title: "操作",
      key: "actions",
      width: "220px",
      render(row) {
        return getActions(row);
      },
    },
  ];
}

function getActions(row) {
  return [
    h(
      NButton,
      {
        style: {
          marginRight: "5px",
        },
        type: "warning",
        size: "tiny",
        onclick: () => onEditRow(row),
      },
      {
        default: () => "编辑",
      }
    ),
    h(
      NButton,
      {
        style: {
          marginRight: "5px",
        },
        type: "success",
        size: "tiny",
        onclick: () => showQRCode(row),
      },
      {
        default: () => "二维码",
      }
    ),
    h(
      NButton,
      {
        style: {
          marginRight: "5px",
        },
        type: "info",
        size: "tiny",
        onclick: () => toDetailPage(row),
      },
      {
        default: () => "详情",
      }
    ),
    h(
      NButton,
      {
        style: {
          marginRight: "5px",
        },
        type: "error",
        size: "tiny",
        onclick: () => deleteRow(row),
      },
      {
        default: () => "删除",
      }
    ),
  ];
}
</script>

<style scoped>
.home-container {
  position: relative;
  background-color: #f0f0f6;
}
.nav-bar {
  width: 100%;
  height: 60px;
  background-color: rgb(147, 105, 51);
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  position: relative;
}

.company-logo {
  /* width: 100px; */
  position: absolute;
  left: 10px;
  top: 20%;
  height: 60%;
  aspect-ratio: 4.7;
  /* background-image: url("@/assets/company_logo.png");
  background-size: 100% 100%; */
}

.nav-text {
  color: #fff;
  text-align-last: justify;
  width: 220px;
  text-align: center;
  margin-left: 20px;
}

.login-out {
  color: #fff;
  /* background-color: #fff; */
  width: 100px;
  cursor: pointer;
}

.content-container {
  width: 100%;
  /* height: calc(100vh - 60px); */
  /* background-color: antiquewhite; */
  padding: 20px;
  box-sizing: border-box;
  position: relative;
}
.buttons-div {
  display: flex;
  position: absolute;
  right: 20px;
  background-color: antiquewhite;
}
</style>

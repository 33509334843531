<template>
  <div class="detail-page">
    <div
      style="
        background-color: rgb(246, 245, 249);
        height: 100vh;
        max-width: 800px;
      "
    >
      <div
        class="nav-bar"
        :style="{
          'background-color': user === '2' ? '#ececec' : 'rgb(147, 105, 51)',
        }"
      >
        <div style="width: 60%">
          <img
            class="company-logo"
            v-if="user === '2'"
            src="@/assets/company_logo_cgcs.png"
            alt=""
          />
          <img
            class="company-logo"
            v-else
            src="@/assets/company_logo.png"
            alt=""
          />
        </div>
      </div>
      <div class="detail-container">
        <div class="sn">
          <div class="info-title">编号：</div>
          <div class="info-value">{{ baseInfo.sn }}</div>
        </div>
        <div class="images">
          <img class="image" :src="baseInfo.frontimg" />
          <!-- <img class="image" :src="baseInfo.backimg" /> -->
          <img
            v-if="showDefaultBackImg"
            class="image"
            :src="baseInfo.defaultBackimg"
          />
          <img
            v-else
            class="image"
            @error="imgOnBackError"
            :src="baseInfo.backimg"
          />
        </div>
        <div
          style="
            background-color: rgb(246, 245, 249);
            height: 15px;
            width: calc(100% - 20px);
            margin: 10px;
          "
        ></div>
        <div class="list-info">
          <div class="info-cell">
            <div class="info-title cell-title">名称：</div>
            <div class="info-value">{{ baseInfo.name }}</div>
          </div>
          <div class="info-cell">
            <div class="info-title cell-title">面值：</div>
            <div class="info-value">{{ baseInfo.facevalue }}</div>
          </div>
          <div class="info-cell">
            <div class="info-title cell-title">材质：</div>
            <div class="info-value">{{ baseInfo.material }}</div>
          </div>
          <div class="info-cell">
            <div class="info-title cell-title">详细信息：</div>
            <div class="info-value">{{ baseInfo.detail }}</div>
          </div>
          <div class="info-cell">
            <div class="info-title cell-title">发行日期：</div>
            <div class="info-value">{{ baseInfo.releasedate }}</div>
          </div>
        </div>
        <div class="bottom-logo">
          <!-- <div class="bottom-line"></div> -->
          <img
            v-if="user === '2'"
            style="width: 80%; margin-top: 20px"
            src="@/assets/company_logo_cgcs_bottom.png"
            alt=""
          />
          <img
            v-else
            style="width: 80%; margin-top: 20px"
            src="@/assets/logo.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import router from "@/router";
import { ref, onMounted } from "vue";
import * as api from "@/api/coin.js";
let baseInfo = ref({
  sn: "",
  name: "",
  facevalue: "",
  material: "",
  detail: "",
  createtime: "",
});
let user = GetUrlParam("u");
let showDefaultBackImg = ref(false);

let default_back_path = ref(new URL("@/assets/back.png", import.meta.url).href);
console.log(default_back_path);
onMounted(async () => {
  queryDetailByCoinId(router.currentRoute.value.query.code);
});
function queryDetailByCoinId(code) {
  api
    .queryDetailById({ id: code })
    .then((res) => {
      console.log(res.data);

      showDefaultBackImg.value = false;
      baseInfo.value = res.data;
    })
    .catch(() => {
      baseInfo.value = {};
      window.$message.warning("没有找到该编号记录");
    });
}
function GetUrlParam(name) {
  var url = window.location.href;

  let params = url.substr(url.lastIndexOf("?") + 1).split("&");
  for (let i = 0; i < params.length; i++) {
    let param = params[i];
    let key = param.split("=")[0];
    let value = param.split("=")[1];
    if (key === name) {
      return value;
    }
  }
}

function imgOnBackError(error) {
  showDefaultBackImg.value = true;
  console.log("图片加载失败", error);
}
</script>

<style scoped>
.detail-page {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f0f0f6;
}
.nav-bar {
  width: 100%;
  height: 60px;
  background-color: rgb(147, 105, 51);
  /* background-color: #f0f0f6; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  border: #ececec 1px solid;
}

.company-logo {
  /* width: 100px; */
  position: absolute;
  left: 10px;
  top: 20%;
  height: 60%;
  aspect-ratio: 4.7;
  /* background-image: url("@/assets/company_logo.png");
  background-size: 100% 100%; */
}

.nav-text {
  color: #fff;
  text-align-last: justify;
  width: 220px;
  text-align: left;
  margin-left: 20px;
}

.detail-container {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.info-title {
  font-weight: 500;
  line-height: 40px;
  color: #000;
}

.info-value {
  /* font-weight: 400; */
  line-height: 40px;
  width: 60%;
  text-align: left;
  /* color: #000; */
}

.sn {
  height: 50px;
  width: 90%;
  /* background-color: red; */
  display: flex;
  /* padding-left: 10px; */
  box-sizing: border-box;
}

.images {
  width: 96%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.image {
  width: 47%;
  /* height: 100px; */
  aspect-ratio: 0.698;
  /* background-color: #f0f0f6; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #adadad;
  border-radius: 8px;
}

.list-info {
  width: 100%;
}

.info-cell {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #999999;
}

.cell-title {
  width: 100px;
  height: 40px;
  /* background-color: #adadad; */
  text-align: right;
}

.bottom-logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(246, 245, 249);
  /* background-color: #999999; */
  /* height: 315px; */
  /* width: 100%; */
  position: relative;
  /* background-color: #ececec; */
}
.bottom-line {
  position: absolute;
  width: 80%;
  height: 1px;
  background-color: #e0e0e0;
  z-index: 0;
  margin: auto;
}
</style>

<template>
  <n-config-provider :locale="zhCN" :date-locale="dateZhCN">
    <router-view />
  </n-config-provider>
</template>
<script setup>
import { NConfigProvider } from "naive-ui";
import { zhCN, dateZhCN } from "naive-ui";
import { useMessage, useDialog } from "naive-ui";

window.$message = useMessage();
window.$dialog = useDialog();
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
  height: 100vh;
  width: 100vw;
  background-color: #f0f0f6;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none;
}

nav a {
  font-weight: bold;
  /* color: #2c3e50; */
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

import axios from "axios"

const service = axios.create({
    baseURL: '/gw',
    timeout: 120000
})

service.interceptors.request.use(
    config => {
        return config
    },
    error => {
        // console.log(error)
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        console.log('response',response);
        if (response.status === 200) {
            if (response.data.code !== undefined) {
                if (response.data.code === 200) {
                    return response.data
                } else {
                    // window.$message.warning(response.data.message);
                    return Promise.reject(response.data.message)
                }
            } else {
                return response
            }

        } else {
            window.$message.warning(response.statusText);
            return Promise.reject(response.statusText)
        }
    },
    error => {
        console.log('error',error);
        return Promise.reject(error.response.data)

    }
)

export default service

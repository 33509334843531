<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    "
  >
    <div
      id="printDiv"
      :style="{
        width: mmToPx(46 * 3) + 'px',
      }"
    >
      <qrcode-label
        v-for="(item, index) in listData.list"
        :key="index"
        :selectProduct="item"
      ></qrcode-label>
    </div>
  </div>
  <div class="button-box" style="margin-top: 30px">
    <n-pagination
      v-model:page="listData.pageNum"
      style="margin-right: 30px"
      @updatePage="updatePage"
      :item-count="listData.total"
      :page-size="searchInfo.limit"
    >
      <!-- <template #prefix="{ itemCount }">
        共 {{ listData.total ? itemCount : 0 }} 条
      </template> -->
    </n-pagination>
    <div style="margin-top: 20px">
      <n-button style="margin-right: 20px" @click="emit('cancel')"
        >取消</n-button
      >
      <n-button type="info" v-print="printObj"
        >打印第{{ currentPage }}页</n-button
      >
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, defineEmits } from "vue";
import { NButton, NPagination } from "naive-ui";
import * as api from "@/api/coin.js";
import { mmToPx } from "@/util/tools.js";
import QrcodeLabel from "./component/QRCodeLabel.vue";
const emit = defineEmits(["cancel"]);
let searchInfo = ref({
  page: 1,
  limit: 9,
});
let listData = ref({
  list: [],
  total: 0,
});
let currentPage = ref(1);

queryListData();
function updatePage(page) {
  searchInfo.value.page = page;
  currentPage.value = page;
  queryListData();
}
async function queryListData() {
  let { data } = await api.queryInfoList(searchInfo.value);
  listData.value = data;

  //   for (let index = 0; index < 4; index++) {
  //     console.log(index);
  //     listData.value.list = [...listData.value.list, ...data.list];
  //   }
}
let printObj = reactive({
  id: "printDiv", //绑定打印区域id
  popTitle: "", //内容标签
  targetStyles: ["*"],
  beforeOpenCallback() {
    console.log("打开之前");
  },
  //调用打印工具成功回调函数
  openCallback() {
    console.log("执行了打印");
  },
  //关闭打印机的回调
  closeCallback() {
    console.log("关闭了打印工具");
  },
});
</script>

<style scoped>
#printDiv {
  /* width: 100%;
  max-height: calc(100vh - 250px); */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* page-break-after: always; */
}

.button-box {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  /* background-color: antiquewhite; */
}
</style>

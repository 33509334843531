<template>
  <div
      id="product-card"
      :style="{
        width: mmToPx(size.width) + 'px',
        height: mmToPx(size.height) + 'px',
        'font-size': mmToPx(2.25) + 'px',
      }"
    >
      <div
        class="qrcode-info"
        :style="{
          width: mmToPx(defaultQRCodeSize) + 'px',
          'margin-top': mmToPx(0) + 'px',
          'margin-left': mmToPx(1.5) + 'px',
          'margin-right': mmToPx(1.5) + 'px',
        }"
      >
        <div
          :id="'qrcode'+selectProduct.id"
          :style="{
            transform: 'scale(' + qrCodeScale + ')',
            width: mmToPx(qrCodeSize) + 'px',
            height: mmToPx(qrCodeSize) + 'px',
            'margin-top':
              -mmToPx((qrCodeSize *3/ 8) - 3) + 'px',
            'margin-left':
              -mmToPx(qrCodeSize *3 / 8) + 'px'
          }"
        ></div>
        <div
          class="qr-code-title"
          :style="{
            width: mmToPx(defaultQRCodeSize + 2) + 'px',
            height: '10px',
            'margin-left': -mmToPx(1) + 'px',
            'margin-top': -mmToPx(qrCodeSize *3/ 8) + 'px',
            'font-size': mmToPx(1.7) + 'px',
            'font-weight':'450'
          }"
        >
          {{ selectProduct.sn }}
        </div>
      </div>
      <div
        class="product-info"
        :style="{
          width: mmToPx(size.width - defaultQRCodeSize - 4.5) + 'px',
          height: mmToPx(defaultQRCodeSize) + 'px',
          'margin-top': mmToPx(3) + 'px',
        }"
      >
        <div class="product-info-text" :style="{
          'line-height':mmToPx(2.25) + 'px',
          'margin-bottom':mmToPx(1) + 'px',
        }">{{ selectProduct.name }}</div>
        <div class="product-info-text" :style="{
          'line-height':mmToPx(2.25) + 'px',
          'margin-bottom':mmToPx(1) + 'px',
        }">{{ selectProduct.facevalue }}</div>
        <div class="product-info-text" :style="{
          'line-height':mmToPx(2.25) + 'px',
          'margin-bottom':mmToPx(1) + 'px',
        }">{{ selectProduct.material }}</div>
      </div>
    </div>
</template>

<script setup>
import { ref, defineProps, toRefs } from "vue";
import QRCode from "qrcodejs2-fix";
// import QrcodeVue from "qrcode.vue";
import { mmToPx } from "@/util/tools.js";
import * as api from "@/api/coin.js";

const props = defineProps({
  selectProduct: {
    type: Object,
    default: () => {
      return null;
    },
  },
});
const { selectProduct } = toRefs(props);
let size = ref({
  width: 46,
  height: 25.13,
});

let defaultQRCodeSize = ref(12);
let qrCodeSize = ref(48);
let qrCodeScale = ref(0.25);
setTimeout(() => {
  createQRCode();
}, 200);
function createQRCode() {
  let content = getQRCodeUrl(selectProduct.value.id);
  let res = new QRCode('qrcode'+selectProduct.value.id, {
    text: content,
    height: mmToPx(qrCodeSize.value),
    width: mmToPx(qrCodeSize.value),
  });
  console.log(res);
}
function getQRCodeUrl(val) {
  let userName = sessionStorage.getItem("userName");
  let user = userName === "admin" ? "1" : "2";
  return api.baseUrl()+"/detail?code=" + val+'&u='+user;
}
</script>

<style scoped>
#product-card {
  display: flex;
  /* justify-content: space-around; */
  flex-direction: row;
  /* background-color: #ececec; */
  border: #66666650 0.1px solid;
  /* margin: 20px; */
  padding: 0;
  box-sizing: border-box;
}
.product-info{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.product-info-text{
  /* background-color: #ececec; */
  /* background-color:red;
  margin-bottom: 1px; */
}
.qrcode-info {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  /* background-color: red; */
}
.qr-code-title {
  text-align: center;
}

</style>
<template>
  <div class="detail-page" style="background-color: #fff; min-height: 300px">
    <div class="nav-bar" style="background-color: #fff; z-index: 999">
      <img class="company-logo" src="@/assets/company_logo_cgcs.png" alt="" />
    </div>
    <div style="background-color: #fff; z-index: 999">
      <div class="page-logo"></div>
    </div>
    <div
      style="
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #fff;
        z-index: 999;
      "
    >
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;
        "
      >
        <n-input
          style="
            max-width: 600px;
            width: 60%;
            height: 50px;
            line-height: 50px;
            margin-right: 10px;
          "
          v-model:value="sn"
          placeholder="请输入产品编号"
          clearable
          @clear="baseInfo = null"
        />
        <n-button
          type="info"
          style="max-width: 100px; width: calc(30% - 50px); height: 48px"
          @click="onsearch"
          >查 询</n-button
        >
      </div>
      <div style="min-height: 20px; width: 100%; margin-top: 60px">
        <div class="detail-container" v-if="baseInfo !== null">
          <h2 style="color: #666666">查询结果</h2>
          <div class="sn">
            <div class="info-title">编号：</div>
            <div class="info-value">{{ baseInfo.sn }}</div>
          </div>
          <div class="images">
            <img class="image" :src="baseInfo.frontimg" />
            <img
              v-if="showDefaultBackImg"
              class="image"
              :src="baseInfo.defaultBackimg"
            />
            <img
              v-else
              class="image"
              @error="imgOnBackError"
              :src="baseInfo.backimg"
            />
          </div>
          <div class="list-info">
            <div class="info-cell">
              <div class="info-title cell-title">名称：</div>
              <div class="info-value">{{ baseInfo.name }}</div>
            </div>
            <div class="info-cell">
              <div class="info-title cell-title">面值：</div>
              <div class="info-value">{{ baseInfo.facevalue }}</div>
            </div>
            <div class="info-cell">
              <div class="info-title cell-title">材质：</div>
              <div class="info-value">{{ baseInfo.material }}</div>
            </div>
            <div class="info-cell">
              <div class="info-title cell-title">详细信息：</div>
              <div class="info-value">{{ baseInfo.detail }}</div>
            </div>
            <div class="info-cell">
              <div class="info-title cell-title">发行日期：</div>
              <div class="info-value">{{ baseInfo.releasedate }}</div>
            </div>
          </div>
        </div>
        <!--  <div
          v-else-if="
            noticeInfo?.detail?.length || noticeInfo?.facevalue === '1'
          "
          class="detail-container"
          style="max-width: 800px; padding: 20px"
        ></div> -->
      </div>
    </div>
    <div
      v-if="baseInfo == null"
      @click="onShowNoticeDetail"
      class="notice-button"
    >
      重要通知
    </div>
    <n-modal
      v-model:show="showDetailModal"
      preset="card"
      style="width: 75vw; max-width: 800px; min-height: 400px"
    >
      <div
        style="
          width: 100%;
          text-align: center;
          font-size: 24px;
          font-weight: 500;
        "
      >
        {{ noticeInfo.name }}
      </div>
      <div
        style="width: 100%; text-align: center; font-size: 12px; color: #adadad"
      >
        {{ noticeInfo.releasedate }}
      </div>
      <div style="max-height: 70vh; overflow: auto">
        <n-scrollbar style="height: 100%">
          <div style="text-align: left">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
              noticeInfo.detail
            }}
          </div>
          <img
            v-if="noticeInfo.facevalue === '1'"
            :src="noticeInfo.frontimg + '?rd=' + Math.random()"
            style="width: calc(100% - 40px); margin: 20px"
          />
        </n-scrollbar>
      </div>
    </n-modal>
  </div>
</template>

<script setup>
// import router from "../router";
import { NButton, NInput, NModal, NScrollbar } from "naive-ui";
import { ref } from "vue";
import * as api from "@/api/coin.js";
// let default_back_path = ref(new URL("@/assets/back.png", import.meta.url).href);
let showEditModal = ref(false);
let sn = ref(null);
let baseInfo = ref(null);
let showDefaultBackImg = ref(false);
let showDetailModal = ref(false);
let noticeInfo = ref();

/* onMounted(() => {
  onShowNoticeDetail();
}); */

function queryDetailByCoinSn(code) {
  if (code === "XC00000000000") {
    window.$message.warning("没有找到该编号记录");
    return;
  }
  api
    .queryDetailByCoinSn({ sn: code })
    .then((res) => {
      showDefaultBackImg.value = false;
      baseInfo.value = res.data;
    })
    .catch(() => {
      baseInfo.value = null;
      window.$message.warning("没有找到该编号记录");
    });
}

function onsearch() {
  showEditModal.value = false;
  queryDetailByCoinSn(sn.value);
}
function onShowNoticeDetail() {
  api
    .queryDetailByCoinSn({ sn: "XC00000000000" })
    .then((res) => {
      showDetailModal.value = true;
      noticeInfo.value = res.data;
    })
    .catch(() => {
      noticeInfo.value = null;
      window.$message.warning("暂无通知");
    });
}

function imgOnBackError(error) {
  showDefaultBackImg.value = true;
  console.log("图片加载失败", error);
}
</script>

<style scoped>
.detail-page {
  width: 100%;
  height: 100vh;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  background-color: #fff;
}
.nav-bar {
  width: 100%;
  height: 60px;
  background-color: #ececec;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 11;
  left: 0;
}

.company-logo {
  /* width: 100px; */
  position: absolute;
  left: 20px;
  top: 20%;
  height: 60%;
  aspect-ratio: 4.7;
  /* background-image: url("@/assets/company_logo.png");
  background-size: 100% 100%; */
}

.detail-container {
  width: 90%;
  max-width: 600px;
  margin: 0px auto;
  /* margin-top: 20px; */
  /* background-color: #f6f6f6; */
  border: #ececec 1px solid;
  border-radius: 4px;
  box-shadow: 0px 7px 17px 1px rgba(83, 90, 102, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.info-title {
  font-weight: 500;
  line-height: 40px;
  color: #000;
}

.info-value {
  /* font-weight: 400; */
  line-height: 40px;
  width: 50%;
  text-align: left;
  /* color: #000; */
}

.sn {
  height: 40px;
  width: 100%;
  /* background-color: red; */
  display: flex;
  padding-left: 5%;
  box-sizing: border-box;
}
.notice-button {
  position: absolute;
  bottom: 80px;
  right: 0;
  left: 0;
  cursor: pointer;
  z-index: 0;
}

.images {
  width: 96%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
}
.image {
  width: 47%;
  /* height: 100px; */
  aspect-ratio: 0.698;
  /* background-color: #f0f0f6; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #adadad;
  border-radius: 8px;
}

.list-info {
  width: 100%;
}

.info-cell {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #999999;
}

.cell-title {
  width: 100px;
  height: 40px;
  /* background-color: #adadad; */
  text-align: right;
}
.page-logo {
  /* width: 200px; */
  width: 257px;
  min-width: 257px;
  height: 100px;
  min-height: 100px;
  /* aspect-ratio: 1.364; */
  margin: 50px;
  margin-top: 30px;
  background-image: url("@/assets/logo_cgcs.png");
  background-size: 100% 100%;
}
</style>

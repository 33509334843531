<template>
  <n-spin :show="showloading">
    <n-form
      ref="formRef"
      :model="formData"
      label-placement="left"
      :label-width="80"
      :rules="rules"
    >
      <n-grid :cols="1" :x-gap="30">
        <n-form-item-gi :span="2" label="旧密码" path="oldPassword">
          <n-input
            v-model:value="formData.oldPassword"
            placeholder="请输入旧密码"
            maxlength="20"
            type="password"
          />
        </n-form-item-gi>
        <n-form-item-gi :span="2" label="新密码" path="password">
          <n-input
            v-model:value="formData.password"
            placeholder="请输入新密码"
            maxlength="20"
            type="password"
          />
        </n-form-item-gi>
        <n-form-item-gi label="确认密码" path="confirmpassword">
          <n-input
            v-model:value="formData.confirmpassword"
            placeholder="请输入确认密码"
            maxlength="20"
            type="password"
          />
        </n-form-item-gi>
      </n-grid>
    </n-form>
    <!-- <div style="margin-left:120px;margin-top:-20px;color:red;font-size:13px;" v-if="showInputError">两次输入密码不一致</div> -->
    <div class="button-container">
      <n-button type="" style="margin: 10px" @click="emit('cancel')"
        >取消</n-button
      >
      <n-button type="info" style="margin: 10px" @click="onSaveClick"
        >修改</n-button
      >
    </div>
  </n-spin>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import { resetPassword } from "@/api/coin.js";
import { NForm, NGrid, NFormItemGi, NInput, NButton, NSpin } from "naive-ui";

const emit = defineEmits(["save", "cancel"]);
let formData = ref({
  password: "",
  confirmpassword: "",
  oldPassword:''
});
let showloading = ref(false);
// let showInputError = ref(false);
const formRef = ref(null);

function onSaveClick() {
  formRef.value?.validate(async (errors) => {
    if (!errors) {
      if (formData.value.password === formData.value.confirmpassword) {
        showloading.value = true;
        resetPassword({
          userName: sessionStorage.getItem("userName"),
          oldPassword: formData.value.oldPassword,
          newPassword: formData.value.password,
        })
          .then(() => {
            window.$message.success("修改成功");
            showloading.value = false;
            emit("save");
          })
          .catch((error) => {
            showloading.value = false;
            window.$message.warning(error);
          });
      } else {
        window.$message.warning("两次输入密码不一致");
      }
    }
  });
}

let rules = ref({
  password: [
    {
      required: true,
      message: "请输入新密码",
    },
  ],
  oldPassword: [
    {
      required: true,
      message: "请输入旧密码",
    },
  ],
  confirmpassword: [
    {
      required: true,
      message: "请再输一次新密码",
    },
  ],
});
</script>

<style>
.button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>

import { createApp } from 'vue'
import pageMain from './pageMain.vue'
import router from './router'
import store from './store'
import Print from 'vue3-print-nb'

router.beforeEach((to, from, next) => {
    console.log('----------------')
    if (to.path == '/cgcs' || to.path == '/search' || to.path == '/detail' || to.path.indexOf('/uploadimg') > -1) {
        next()
    }

    let userName = sessionStorage.getItem('userName')
    if (to.path !== '/' && to.path !== '/login') {
        if (userName) {
            next()
        } else if (to.path !== '/') {
            next('/')
        }
    } else if (to.path === '/') {
        next('/login')
    } else {
        next()
    }
})


createApp(pageMain).use(store).use(router).use(Print).mount('#app')


import request from '@/api/request-gw'
import jsZIP from "jszip";
import saveAs from "file-saver";
// import XLSX from 'xlsx';

export function baseUrl() {
    return 'https://xc.cgcscoin.cn';
}
export function addInfo(params) {
    let formData = new FormData();
    for (let key in params) {
        if (params[key] !== null) formData.append(key, params[key]);
    }

    if (params.id) {

        return request({
            url: '/updateInfo',
            method: 'post',
            data: formData,
            headers: {
                'content-type': 'multipart/form-data',
            }
        })
    } else {
        return request({
            url: '/addInfo',
            method: 'post',
            data: formData,
            headers: {
                'content-type': 'multipart/form-data',
            }
        })
    }

}
export function batchUpload(data) {
    console.log('bbb')
    let formData = new FormData();
    if (data.excel.length)
        formData.append('excel', data.excel[0].file);
    if (data.picZip.length)
        formData.append('picZip', data.picZip[0].file);

    return request({
        url: '/batchUpdate',
        method: 'post',
        data: formData,
        headers: {
            'content-type': 'multipart/form-data',
        }
    })
}

export function deleteInfo(data) {
    return request({
        url: '/deleteInfo',
        method: 'post',
        data
    })
}
export function login(data) {
    return request({
        url: '/login',
        method: 'post',
        data
    })
}
export function resetPassword(data) {
    return request({
        url: '/resetPassword',
        method: 'post',
        data
    })
}
export async function queryDetailById(params) {

    let res = await request({
        url: '/queryDetailById',
        method: 'get',
        params
    })
    return new Promise((resolve) => {
        let sn = res.data.sn;
        let preText =  sn.split("-")[0];
        res.data.defaultBackimg = baseUrl() + '/uploadimg/' + preText + '_back.jpg';
        res.data.frontimg = baseUrl() + '/uploadimg/' + res.data.sn + '.jpg';
        res.data.backimg = baseUrl() + '/uploadimg/' + res.data.sn + '_back.jpg';
        resolve(res);
    });
}
export async function queryDetailByCoinSn(params) {
    let res = await request({
        url: '/queryDetailByCoinSn',
        method: 'get',
        params
    })

    return new Promise((resolve) => {
        let sn = res.data.sn;
        let preText =  sn.split("-")[0];
        res.data.defaultBackimg = baseUrl() + '/uploadimg/' + preText + '_back.jpg';
        res.data.frontimg = baseUrl() + '/uploadimg/' + res.data.sn + '.jpg';
        res.data.backimg = baseUrl() + '/uploadimg/' + res.data.sn + '_back.jpg';
        resolve(res);
    });
}
export function queryInfoList(params) {
    return request({
        url: '/queryInfoList',
        method: 'get',
        params
    })
}
// export async function exportExcelTest(params) {
//     let res = await request({
//         url: '/exportExcel',
//         method: 'get',
//         responseType: "blob",
//         params
//     })
//     return new Promise((resolve) => {
        
//         console.log('res.data', res.data);
//         let datajson = XLSX.read(res.data);
//         console.log('datajson', datajson);
//         resolve(res);
//     });
// }
export function exportExcel(params) {
    return request({
        url: '/exportExcel',
        method: 'get',
        responseType: "blob",
        params
    })
}
export function exportQRCode(params) {
    return request({
        url: '/exportQRCode',
        method: 'get',
        responseType: "blob",
        params,
        onDownloadProgress: (progress) => {
            console.log(progress);
        }
    })
}
export function updateInfo(data) {
    return request({
        url: '/updateInfo',
        method: 'post',
        data
    })
}

export function handleBlobFile(res) {
    console.log("handleBlobFile---", res);
    if (res.data.type == "application/json") {
        const file = new FileReader();
        file.readAsText(res.data, "utf-8");
        file.onload = function () {
            const resData = JSON.parse(file.result);
            window.$message.warning(resData.message);
        };
    } else {
        const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        const downloadElement = document.createElement("a");
        const href = window.URL.createObjectURL(blob);
        //从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
        let contentDisposition = res.headers["content-disposition"];
        let filename = decodeURIComponent(contentDisposition.split("filename=")[1]);
        downloadElement.style.display = "none";
        downloadElement.href = href;
        downloadElement.download = filename;
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);

        window.$message.success("下载完成");
    }
}

/**
 * @description 批量下载
 * @author easten
 * @date 2020-07-13
 * @export
 * @param {*} zipName 压缩包名称
 * @param {*} files 需要压缩的文件信息
 */
export function SaveMultipleFile(zipName, files) {
    return new Promise((resolve, error) => {
        // 首先定义一个文件夹
        let zip = new jsZIP();
        console.log("saveAs", zip);
        files.forEach((a) => {
            zip.file(a.name, a.blob);
        });
        zip
            .generateAsync({ type: "blob" })
            .then((content) => {
                let res = saveAs(content, zipName);
                console.log("res", res);
                resolve(res);
            })
            .catch((err) => {
                console.log("error", err);
                error(err);
            });
    });
}

export function blobToBase64(blob, callback) {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
        callback(e.target.result);
    };
    fileReader.readAsDataURL(blob);
}
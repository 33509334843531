<template>
  <n-spin :show="showloading">
    <n-form
      ref="formRef"
      :model="formData"
      label-placement="left"
      label-width="100"
      :rules="rules"
    >
      <n-grid :cols="1" :x-gap="10">
        <n-form-item-gi label="通知标题" path="name">
          <n-input v-model:value="formData.name" placeholder="请输入通知标题" />
        </n-form-item-gi>
        <n-form-item-gi label="发布日期" path="releasedate">
          <n-date-picker
            value-format="yyyy-MM-dd"
            placeholder="请选择发布日期"
            type="date"
            style="width: 100%"
            v-model:formatted-value="formData.releasedate"
          />
        </n-form-item-gi>
        <n-form-item-gi label="通知内容" path="detail">
          <n-input
            v-model:value="formData.detail"
            placeholder="请输入通知内容"
            type="textarea"
            maxlength="290"
            :autosize="{
              minRows: 5,
              maxRows: 9,
            }"
          />
        </n-form-item-gi>
        <n-form-item-gi label="附件图片" path="frontImg">
          <n-upload
            :max="1"
            list-type="image-card"
            :file-list="frontImg"
            @update:file-list="handleFrontImgChange"
            @before-upload="beforeUpload"
          >
          </n-upload>
        </n-form-item-gi>
      </n-grid>
    </n-form>
    <div class="button-container">
      <n-button type="" style="margin: 10px" @click="onCancelClick"
        >取消</n-button
      >
      <n-button type="info" style="margin: 10px" @click="onSaveClick"
        >发布</n-button
      >
    </div>
    <img @error="imgOnFrontError" :src="frontimgPath" v-show="false" />
    <img @error="imgOnBackError" :src="backimgPath" v-show="false" />
  </n-spin>
</template>

<script setup>
import { ref, defineEmits, defineProps, toRefs, onMounted } from "vue";
import { addInfo, baseUrl, queryDetailByCoinSn } from "@/api/coin";
// import axios from "axios"
// import ActiveXObject
import {
  NForm,
  NGrid,
  NFormItemGi,
  NInput,
  NButton,
  NUpload,
  NSpin,
  NDatePicker,
} from "naive-ui";

const props = defineProps({
  selectProduct: {
    type: Object,
    default: () => {
      return null;
    },
  },
});
const { selectProduct } = toRefs(props);
let formData = ref({
  sn: "",
  name: "",
  facevalue: "0",
  detail: "",
  releasedate: "2023-01-01",
});
let frontImg = ref([]);
let backImg = ref([]);
let showloading = ref(false);
const formRef = ref(null);
const emit = defineEmits(["save", "cancel"]);
let noticeSN = ref("XC00000000000");

let frontimgPath = baseUrl() + "/uploadimg/" + noticeSN.value + ".jpg";
let backimgPath =
  baseUrl() + "/uploadimg/" + selectProduct.value?.sn + "_back.jpg";

onMounted(() => {
  /* { sn: "XC00000000000" } */
  queryDetail(noticeSN.value);
});

function queryDetail(code) {
  queryDetailByCoinSn({ sn: code })
    .then((res) => {
      initData(res.data);
    })
    .catch(() => {
      formData.value = { sn: noticeSN.value };
      //window.$message.warning("没有找到该编号记录"); /*  */
    });
}

function initData(coin) {
  formData.value = {
    id: coin.id,
    sn: coin.sn,
    name: coin.name,
    detail: coin.detail,
    frontimg: coin.frontimg,
    facevalue: coin.facevalue,
    releasedate: coin.releasedate,
  };
  if (coin.facevalue === "1")
    frontImg.value = [
      {
        id: 1,
        url: frontimgPath + "?rd=" + Math.random(),
        status: "finished",
        name: "正面",
      },
    ];
}

function imgOnFrontError(error) {
  console.log("--err", error);
  frontImg.value = [];
}
function imgOnBackError(error) {
  console.log("--err", error);
  backImg.value = [];
}
function onCancelClick() {
  emit("cancel");
}
function onSaveClick() {
  formRef.value?.validate(async (errors) => {
    if (!errors) {
      showloading.value = true;
      console.log(formData);
      addInfo(formData.value)
        .then((res) => {
          if (res.code == 200) {
            window.$message.success("发布成功");
            emit("save");
          } else {
            window.$message.warning("请求失败，请稍后再试");
          }
          showloading.value = false;
        })
        .catch((error) => {
          console.log("error", error);
          showloading.value = false;
          window.$message.warning(error.message);
        });
    }
  });
}

function handleFrontImgChange(options) {
  if (options.length) {
    formData.value.frontImg = options[0].file;
    frontImg.value = options;
    formData.value.facevalue = "1";
  } else {
    formData.value.frontImg = null;
    formData.value.facevalue = "0";
    frontImg.value = [];
  }
}

function beforeUpload(data) {
  console.log("tag", data.file.file);
  if (data.file.file?.type === "image/jpeg") {
    return true;
  }
  window.$message.warning("请上传jpg格式图片");
  return false;
}

let rules = ref({
  name: [
    {
      required: true,
      message: "请输入通知标题",
    },
  ],
  releasedate: [
    {
      required: true,
      message: "请选择发布日期",
    },
  ],
  detail1: [
    {
      required: true,
      message: "请输入内容",
    },
  ],
});
</script>

<style>
.button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>

<template>
  <div class="qr-code-container">
    <!-- <qrcode-label :selectProduct='selectProduct'></qrcode-label> -->

    <!-- <n-button style="margin-top:30px" type="info" v-print="printObj">打印二维码</n-button> -->

    <div style="margin-bottom:40px;margin-top:10px;font-size:18px;font-weight:500"> {{selectProduct.name}}</div>
    <div id="product-qrcode"></div>
    <div style="margin-top:10px"> {{selectProduct.sn}}</div>
    <!-- <n-button style="margin-top:10px" type="">关闭</n-button> -->
  </div>
</template>

<script setup>
// import { NButton } from "naive-ui";
import { defineProps, toRefs} from "vue";
// import QrcodeLabel from "./QRCodeLabel.vue"
import QRCode from "qrcodejs2-fix";
import * as api from "@/api/coin.js";

const props = defineProps({
  selectProduct: {
    type: Object,
    default: () => {
      return null;
    },
  },
});
const { selectProduct } = toRefs(props);

setTimeout(() => {
  createQRCode();
}, 200);
function createQRCode() {
  let content = getQRCodeUrl(selectProduct.value.id);
  let res = new QRCode('product-qrcode', {
    text: content,
    height: 120,
    width: 120,
  });
  console.log(res);
}
function getQRCodeUrl(val) {
let userName = sessionStorage.getItem("userName");
let user = (userName==='admin')?'1':'2';
  return api.baseUrl()+"/detail?code=" + val+'&u='+user;
}
// let printObj = reactive({
//   id: "product-card", //绑定打印区域id
//   popTitle: "", //内容标签
//   targetStyles: ["*"],
//   beforeOpenCallback() {
//     console.log("打开之前");
//   },
//   //调用打印工具成功回调函数
//   openCallback() {
//     console.log("执行了打印");
//   },
//   //关闭打印机的回调
//   closeCallback() {
//     console.log("关闭了打印工具");
//   },
// });
</script>

<style scoped>
.qr-code-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

</style>
